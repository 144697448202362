export const Companies = [
  {
    label : "Allstate",
    value : "Allstate Insurance",
    isInput: false,
    next: null
  },
  {
    label : "GEICO",
    value : "Geico Casualty",
    isInput: false,
    next: null
  },
  {
    label : "Nationwide",
    value : "Nationwide General Insurance",
    isInput: false,
    next: null
  },
  {
    label : "State Farm",
    value : "State Farm County",
    isInput: false,
    next: null
  },
  {
    label : "USAA",
    value : "USAA",
    isInput: false,
    next: null
  },
  {
    label : "Farmers",
    value : "Farmers Insurance",
    isInput: false,
    next: null
  },
  {
    label : "Liberty Mutual",
    value : "Liberty Mutual Insurance",
    isInput: false,
    next: null
  },
  {
    label : "Progressive",
    value : "Progressive",
    isInput: false,
    next: null
  },
  {
    label : "Travelers",
    value : "Travelers Insurance Company",
    isInput: false,
    next: null
  },
  {
    label : "Other",
    value : "Company Not Listed",
    isInput: false,
    next: null
  },
]
