import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit {

  public year: any;

  constructor() { }

  ngOnInit(): void {
  }

  get currentYear(){
    return this.year = new Date().getFullYear();
  }

}
