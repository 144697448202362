<app-site-header></app-site-header>

<section id="text-page">

    <h1>Do Not Sell My Information</h1>
  
    <br /><br />
  
    <p>For California and Nevada residents, some of PolicyBind, LLC's (which does business as Car Insurance Deals ("we" or "us" or "Car Insurance Deals")), sharing activities may be considered a "sale" of personal information under applicable state law. This includes when we share information collected from cookies and similar tracking technologies (such as how you interact with our Sites) with our Third-Party Partners or third party service providers in order to create user profiles and present advertising messages based on your preferences and other information we have collected from you (to learn more see "Information Collection Technologies and Cookies").</p>
  
    <p>Subject to certain exceptions, as a California or Nevada resident, you can change your browser options to stop accepting cookies or to prompt you before accepting cookies to opt-out of the collection and sharing of your information. Note that certain aspects of our service may be limited if you do not accept cookies. More information about cookies and other tracking technologies is available at allaboutcookies.org or youronlinechoices.eu.</p>
  
    <p>Some web browsers may transmit "do not track" signals. We do not take action to respond to do not track signals available on some browsers. This is because web browsers may incorporate or activate these features differently, making it unclear if users have consciously activated them. As a result, at this time we do not take steps to respond to such signals. As noted above, you can manage your cookie preferences using your own browser settings to accept or block some or all cookies or receive notice so you can consent to cookies. If you block all cookies some features of our Sites may be unavailable or limited.</p>
  
    <p>Keep in mind that, if you opt out of the collection and use of information via cookies and other tracking technologies, you may still see customized advertising displayed by advertising networks from which you have not opted out. In addition, your opt-out will not prevent any advertising network from displaying advertising that is not customized to you and your interests and, consequently, may be less relevant to you. Please also note that you will need to make such choices on each browser and device you may use to visit our Sites.</p>
  
    <p><strong>INFORMATION YOU PROVIDE TO CAR INSURANCE DEALS</strong></p>
  
    <p>For California and Nevada residents, information that you provide to us through our Sites, Webforms, telephone calls, mail, and email is shared at your direction with our Third-Party Partners (see our full <a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a> to learn more) which may be considered a "sale" under applicable state laws. If you wish to put on file with us your preference to opt-out of our sharing of your personal Information with third parties for such third parties' direct marketing purposes and business purposes or to find out more about your opt-out rights, please complete the form below or call us at 1-833-549-0351.</p>
  
    <p>If you wish to submit an opt-out request as a representative of a consumer or household, please email our Data Privacy Officer at info@policybind.com or call us at 1-833-549-0351.</p>
  
  
  
  </section>

  <app-site-footer></app-site-footer>