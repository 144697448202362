import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thankyou-page',
  templateUrl: './thankyou-page.component.html',
  styleUrls: ['./thankyou-page.component.scss']
})
export class ThankyouPageComponent implements OnInit {

  zipcode: string;

    //Final steps containers
    public details = true;
    public clicks = false;
    //Ministeps titles
    public miniSteps = {
      details: true,
      location: false,
      options: false,
      availability: false,
      congrats: false
    };


    public agentsAvailable = false;
  
  constructor() { }

  ngOnInit(): void {
    this.getLocalStorageData();
    this.processingAnimations();

    const $this = this;
    setTimeout(function(){
      $this.loadThankyouScripts();
    }, 900);   
  }

  getLocalStorageData(){
    this.zipcode = localStorage.getItem('zipcode') || "";
  }

  mediaAlphaInit(){

    const $this = this;
    setTimeout(function(){

      let subId1 = '1'; //($this.p_id !== "") ? $this.p_id : "";

      (<any>window).MediaAlphaExchange = {
        "data": {
          "zip": $this.zipcode,
        },
        "placement_id": "n5DbIO93uYTlxCydFjLsilLeA5qO8g",
        "sub_1": subId1,
        "type": "ad_unit",
        "version": 17
      };

      (<any>window).MediaAlphaExchange__load('mediaalpha_placeholder');
      $this.clicks = true;
      console.log('clicks');
      console.log($this.clicks);
     }, 1500);
  }

  formatDate(y: string, m: string, d: string){
    if(y.trim() !== "" && m.trim() !== "" && d.trim() !== ""){
      const datepipe: DatePipe = new DatePipe('en-US');
      return datepipe.transform(m+'/'+d+'/'+y, 'YYYY-MM-dd');
    }

    return '';
  }

  loadThankyouScripts() {

    //let conversionPixel = this.getConversionPixel(this.lp_request_id, this.p_id);

    const dynamicScripts = [
     'https://insurance.mediaalpha.com/js/serve.js'
     //,conversionPixel
    ];

    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      document.getElementsByTagName('head')[0].appendChild(node);
    }

  }

  processingAnimations(){
    const $this = this;
     setTimeout(function(){
        $this.miniSteps.details = false;
        $this.miniSteps.location = true;
    }, 4000);
    setTimeout(function(){
      $this.miniSteps.location = false;
      $this.miniSteps.options = true;
    }, 6000);
    setTimeout(function(){
      $this.miniSteps.options = false;
      $this.miniSteps.availability = true;
    }, 8000);
    setTimeout(function(){
      $this.miniSteps.availability = false;
      $this.miniSteps.congrats = true;
    }, 10000);    
    setTimeout(function(){
      $this.details = false;
      //$this.mediaAlphaInit();
      $this.startTimer();
      $this.agentsAvailable = true;
    }, 13000);  
  }

  public agentTimer: string = '00:00';
  startTimer(duration: any = 120) {
    let timer = duration;
    let minutes: any;
    let seconds: any;
    let $this = this;
    setInterval(function () {
        minutes = parseInt(''+timer / 60, 10);
        seconds = parseInt(''+timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        $this.agentTimer = minutes + ":" + seconds;

        if (--timer < 0) {
            timer = duration;
        }
    }, 1000);
  }

}
