import { Companies } from "./lists/insurance.companies"
import * as _ from 'lodash';

export class AutoQuestions {


  public Questions = [

    {
      name : "currentlyInsured",
      label : "Do you have car insurance?",
      answers: [
        { label: "Yes", value: "yes", isInput: false, next: "currentInsurer"},
        { label: "No", value: "no", isInput: false, next: "drivers"},
      ],
      next: null,
      active: true,
      firstQuestion: true,
      progress: 0
    },
    {
      name : "currentInsurer",
      label : "Who is your current insurer?",
      answers : Companies,
      next: "insuredTime",
      active: true,
      answer_column_split: 2,
      progress: 15
    },
    {
      name : "insuredTime",
      label : "How long have you continuously had auto insurance?",
      //Answer should be in months
      answers: [
        { label: "Less than 1 year", value: "12", isInput: false, next: null},
        { label: "1-2 years", value: "24", isInput: false, next: null},
        { label: "2-3 years", value: "36", isInput: false, next: null},
        { label: "3-4 years", value: "48", isInput: false, next: null},
        { label: "4-5 years", value: "60", isInput: false, next: null},
        { label: "5 years or more", value: "61", isInput: false, next: null}
      ],
      next: "drivers",
      active: true,
      progress: 20
    },
    {
      name : "drivers",
      label : "How many drivers?",
      answers: [
        { label: "1", value: "1", isInput: false, next: null},
        { label: "2", value: "2", isInput: false, next: null},
        { label: "3+", value: "3", isInput: false, next: null}
      ],
      next: "vehicleYear",
      active: true,
      progress: 20
    },


    /** VEHICLE PARAMETERS > WE CAN ADD UP TO 2 VEHICLES FOR NOW */
    /** FIRST VEHICLE*/
    {
      name : "vehicleYear",
      label : "What is your vehicle year?",
      is_dropdown: true,
      is_year_list: true,
      /* List of vehicle year should come from a car API*/
      answers: [
        { label: "Prior years", value: "Prior years", disabled: true, isInput: true, next: null},
      ],
      next: "vehicleMake",
      active: true,
      progress: 30
    },
    {
      name : "vehicleMake",
      label : "What is your vehicle make?",
      is_dropdown: true,
      is_make_list: true,
      /* List of vehicle makes should come from a car API*/
      answers: [
        { label: "Other make", value: "Other make", disabled: true, isInput: true, next: null},
      ],
      next: "vehicleModel",
      active: true,
      progress: 35
    },
    {
      name : "vehicleModel",
      label : "What is the model of your [MAKE]?",
      is_dropdown: true,
      is_model_list: true,
      /* List of vehicle models should come from a car API*/
      answers: [],
      next: "vehicleTrim",
      active: true,
      progress: 40
    },
    {
      name : "vehicleTrim",
      label : "What is the trim of your [MAKE] [MODEL]?",
      is_dropdown: true,
      is_trim_list: true,
      /* List of vehicle trims should come from a car API*/
      answers: [],
      next: "anotherVehicle",
      active: true,
      progress: 45
    },
    /** SECOND VEHICLE*/
    {
      name : "vehicleYear2",
      label : "What is your vehicle year?",
      is_dropdown: true,
      is_year_list: true,
      /* List of vehicle year should come from a car API*/
      answers: [ ],
      next: "vehicleMake2",
      active: true,
      progress: 50
    },
    {
      name : "vehicleMake2",
      label : "What is your vehicle make?",
      is_dropdown: true,
      is_make_list: true,
      /* List of vehicle makes should come from a car API*/
      answers: [],
      next: "vehicleModel2",
      active: true,
      progress: 52
    },
    {
      name : "vehicleModel2",
      label : "What is the model of your [MAKE2]?",
      is_dropdown: true,
      is_model_list: true,
      /* List of vehicle models should come from a car API*/
      answers: [],
      next: "vehicleTrim2",
      active: true,
      progress: 54
    },
    {
      name : "vehicleTrim2",
      label : "What is the trim of your [MAKE2] [MODEL2]?",
      is_dropdown: true,
      is_trim_list: true,
      /* List of vehicle trims should come from a car API*/
      answers: [],
      next: "accidentsTickets",
      active: true,
      progress: 56
    },
    /* END VEHICLE PARAMTERS */

    {
      name : "anotherVehicle",
      label : "Would you like to add another vehicle?",     
      max_vehicles: 2,
      answers: [
        { label: "Yes", value: "yes", isInput: false, next: "vehicleYear2"},
        { label: "No", value: "no", isInput: false, next: "accidentsTickets"},
      ],
      next: null,
      active: true,
      progress: 47
    },
    {
      name : "accidentsTickets",
      label : "In the past 3 years, have you had any Accidents or Tickets?",      
      answers: [
        { label: "Yes", value: "1", isInput: false, next: null},
        { label: "No", value: "0", isInput: false, next: null},
      ],
      next: "dui",
      active: true,
      progress: 60
    },
    {
      name : "dui",
      label : "Have you had a DUI",      
      answers: [
        { label: "Yes", value: "yes", isInput: false, next: null},
        { label: "No", value: "no", isInput: false, next: null},
      ],
      next: "sr22",
      active: true,
      progress: 65
    },
    {
      name : "sr22",
      label : "Do you need a SR-22",      
      answers: [
        { label: "Yes", value: "yes", isInput: false, next: null},
        { label: "No", value: "no", isInput: false, next: null},
      ],
      next: "credit",
      active: true,
      progress: 70
    },
    {
      name : "credit",
      label : "What is your credit rating?",      
      answers: [
        { label: "Excellent", value: "excellent", isInput: false, next: null},
        { label: "Good", value: "good", isInput: false, next: null},
        { label: "Average", value: "average", isInput: false, next: null},
        { label: "Poor", value: "poor", isInput: false, next: null}
      ],
      next: "married",
      active: true,
      progress: 74,
    },
    {
      name : "married",
      label : "Are you currently married?",      
      answers: [
        { label: "Yes", value: "yes", isInput: false, next: null},
        { label: "No", value: "no", isInput: false, next: null},
      ],
      next: "homeOwner",
      active: true,
      progress: 77,
    },
    {
      name : "homeOwner",
      label : "Are you a home owner?",      
      answers: [
        { label: "Yes", value: "yes", isInput: false, next: "homeInsurance"},
        { label: "No", value: "no", isInput: false, next: "dob"},
      ],
      next: null,
      active: true,
      progress: 81,
    },
    {
      name : "homeInsurance",
      label : "Are you interested in home insurance quotes too?",      
      answers: [
        { label: "Yes", value: "1", isInput: false, next: null},
        { label: "No", value: "0", isInput: false, next: null},
      ],
      next: "dob",
      active: true,
      progress: 84,
    },
    {
      name : "dob",
      label : "What is your date of birth?",
      is_dropdown: true,
      is_dob: true,    
      show_button: true,
      answers: [
        { label: "Month", value: "", isInput: true, next: null},
        { label: "Day", value: "", isInput: true, next: null},
        { label: "Year", value: "", isInput: true, next: null}
      ],
      next: "gender",
      active: true,
      required: true,
      error_message: "Please enter a valid date.",
      progress: 86
    },
    {
      name : "gender",
      label : "What is your gender?",      
      answers: [
        { label: "Female", value: "female", isInput: false, next: null},
        { label: "Male", value: "male", isInput: false, next: null},
        { label: "Other", value: "other", isInput: false, next: null},
      ],
      next: "military",
      active: true,
      progress: 89,
    },
    {
      name : "military",
      label : "Are either you or your spouse an active member or an honorably discharged veteran of the US military?",      
      answers: [
        { label: "Yes", value: "yes", isInput: false, next: null},
        { label: "No", value: "no", isInput: false, next: null},
      ],
      next: "zip",
      active: true,
      progress: 91,
    },
    {
      name : "zip",
      label : "What is your zip code?",      
      show_button: true,
      answers: [
        { label: "Zip code", value: "", isInput: true, next: null}
      ],
      next: "address",
      active: true,
      progress: 93,
      required: true,
      error_message: "Please enter a valid 5 digit zipcode.",
    },
    {
      name : "address",
      label : "Where do you live?",
      show_button: true,
      answers: [
        { label: "Address", value: "", isInput: true, next: null }
      ],
      next: "name",
      active: true,
      progress: 95,
      required: true,
      error_message: "Please enter a valid address.",
    },
    {
      name : "name",
      label : "Who are these free quotes for?",      
      show_button: true,
      is_name: true,
      answers: [
        { label: "Name", value: "", isInput: true, next: null },
        { label: "Last Name", value: "", isInput: true, next: null },
      ],
      next: "email",
      active: true,
      progress: 96,
      required: true,
      error_message: "Please enter a valid name.",
    },
    {
      name : "email",
      label : "Where are we sending your free quotes?",
      show_button: true,
      answers: [
        { label: "Email", value: "", isInput: true, next: null }
      ],
      next: "phone",
      active: true,
      progress: 97,
      required: true,
      error_message: "Please enter a valid email.",
    },
    {
      name : "phone",
      label : "Let us know the best way to get you the details!",      
      show_button: true,
      inputType: "tel",
      inputMask: "(000) 000-0000",
      answers: [
        { label: "Phone", value: "", isInput: true, next: null }
      ],
      next: null,
      active: true,
      lastQuestion: true,
      progress: 99,
      required: true,
      error_message: "Please enter a valid phone number.",
      button_text: "View My Results"
    }
  ];

  get getFirstQuestion(): any {
    return this.Questions.find(q => {
      return q.firstQuestion === true;
    });
  }

  /**
   * next()
   * returns the next question given a current question
   * param @questionName = The name of the current question
   * param @answerVal = The value of the selected answer for the current question
   */
  public next(questionName: string = "", answerVal: any, currentVhicles: number = 1, maxAllowed: number = 2) {
    // The expected next question object
    let nextQuestion = {};
    let nextQuestionName = "";

    // First we get the full question object
    const question = this.Questions.find(q => {
      return q.name === questionName;
    });

    if(_.isEmpty(question)) {
      // Exit if no question matched
      return false;
    }

    // Then we need to know if the current answer changes the form flow by not having null assigned to its .next property
    const answer = question.answers.find(a => {
      return a.value === answerVal;
    });

    if(_.isEmpty(answer) || answer.next === null){
      // We use the question global next value
      nextQuestionName = question.next;
    } else {
      // We use the answer next value
      nextQuestionName = answer.next;
    }

    if(nextQuestionName !== null){
      nextQuestion = this.Questions.find(nq => {
        return nq.name === nextQuestionName;
      });

      return nextQuestion;
    } else {
      // No next question was found
      return false;
    }
  }

    /**
   * prev()
   * returns the previous question given a saved previous question name
   * param @questionName = The name of the current question
   * param @answerVal = The value of the selected answer for the current question
   */
     public prev(previousQuestionName: string = "") {
      // The expected next question object
  
      // First we get the full question object
      const prevQuestion = this.Questions.find(q => {
        return q.name === previousQuestionName;
      });
  
      if(_.isEmpty(prevQuestion)) {
        // Exit if no question matched
        return false;
      }

      return prevQuestion;

    }

  /**
   * getQuestionByName()
   * returns the question object from its name
   * param @questionName = The name of the current question
   */
  public getQuestionByName(questionName: string){
    const question = this.Questions.find(q => {
      return q.name === questionName;
    });
    return question;
  }


}
