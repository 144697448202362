<section id="side-header">

  <div class="container">
    <div class="row desktop">
        <div class="col-md-3">
            <div id="logo-container">
                <img src="../assets/images/car-logo.png" alt="CAR INSURANCE DEALS" id="top-logo-img">
            </div>
        </div>

        <div class="col-md-3"></div>

        <div class="col-md-5">
            <div id="top-number">
                <p class="small-text-1">
                    Speak to a Licensed Insurance Agent
                </p>
                <a href="tel:+18886041151" class="phone-number-text-1">
                    (888) 604-1151
                </a>
                <p class="small-text-2">
                  <span id="circle-1"></span>
                  Licensed Agents Available Now
                </p>
            </div>
        </div>
        <div class="col-md-1">
          <span id="help-avatar"></span>
        </div>
    </div>


    <div class="row mobile">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12">
            <img routerLink="/" src="../assets/images/car-logo.png" alt="Medicare Plan Help" id="top-logo-img-mobile">
            <a id="mobile-top-link" class="button" (click)="customClickEvent('homepage-mobile-header-button')" href="tel:+18886041151">
              <i class="phone-icon"></i> Talk to an Agent
            </a>
          </div>
        </div>

      </div>
    </div>
  </div>

</section>
