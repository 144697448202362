import { HttpClient } from "@angular/common/http";

export class DoPost {
    constructor(private http:HttpClient){}

    public makeHttpPostRequest(formFields: any) {
        this.http.post<any>('https://owned-and-operated-324915.uc.r.appspot.com/api/lpfpauto', formFields).subscribe(response => {
          formFields['response'] = response;
          this.saveToDatastore(formFields);
        });
      }
    
      public saveToDatastore(formFields: any){
        this.http.post<any>('https://owned-and-operated-324915.uc.r.appspot.com/api/svld', {lead_data: JSON.stringify(formFields), entity: "auto"}).subscribe(response => {
        });
      }

}
