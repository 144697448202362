import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CarsApiService {

  private carApiBaseUrl: string = "https://carmakemodeldb.com/api/v1/car-lists/get/";
  private carApiToken: string = "Jiwp0hAwYjDcG62tSAxwQMwpLcp8wKOEoVjRmIQv2nA1RhMqNpDUoDJO0USy";

  constructor(private http:HttpClient) { }

  getYears(){
    return this.http.get<any>(this.carApiBaseUrl+"years/desc?api_token="+this.carApiToken);
  }

  getMakesByYear(year: string){
    return this.http.get<any>(this.carApiBaseUrl+"makes/"+year+"?api_token="+this.carApiToken);
  }

  getModelsByMake(year: string, make: string){
    return this.http.get<any>(this.carApiBaseUrl+"models/"+year+"/"+make+"?api_token="+this.carApiToken);
  }

  getTrimsByModel(year: string, make: string, model: string){
    return this.http.get<any>(this.carApiBaseUrl+"trims/"+year+"/"+make+"/"+model+"?api_token="+this.carApiToken);
  }

}
