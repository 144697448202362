import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  customClickEvent(eventName: string) {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'button-click',
      data: eventName,
    };
    //this.gtmService.pushTag(gtmTag);
  }

}
