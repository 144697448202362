import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CampaignFlowComponentComponent } from './campaign-flow-component/campaign-flow-component.component';
import { DonotSellComponent } from './donot-sell/donot-sell.component';
import { PartnersListComponent } from './partners-list/partners-list.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermnsConditionsComponent } from './termns-conditions/termns-conditions.component';
import { ThankyouPageComponent } from './thankyou-page/thankyou-page.component';

const routes: Routes = [
  { path: '',   redirectTo: '/auto', pathMatch: 'full' },
  { path: 'auto', component: CampaignFlowComponentComponent },
  {path: 'auto/thankyou', component: ThankyouPageComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'terms-and-conditions', component: TermnsConditionsComponent},
  {path: 'do-not-sell', component: DonotSellComponent},
  {path: 'partners', component: PartnersListComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
