<app-site-header></app-site-header>

<section id="content-banner">
  <div id="banner">
    <p>{{bannerText}}</p>
  </div>
  <div id="progress">
    <circle-progress
      [percent]="progressNumber"
      [radius]= "60"
      [space]= "-10"
      [outerStrokeWidth]= "10"
      [outerStrokeColor]= "'#2c88d9'"
      [outerStrokeGradientStopColor]= "'#53a9ff'"
      [innerStrokeColor]= "'#e7e8ea'"
      [innerStrokeWidth]= "10"
      [animateTitle]= "false"
      [animationDuration]= "1000"
      [backgroundColor]="'#fff'"
      [backgroundPadding]="0"
      [showBackground]= "true"
      [startFromZero]= "false"
      [subtitle]="stepText"
      [titleColor]="'#818697'"
      [titleFontSize]="'24'"
      [subtitleColor]="'#818697'"
      [subtitleFontSize]="stepTextSize"
      [showTitle]='showTitleAndUnit'
      [showUnits]= 'showTitleAndUnit'
    >
    </circle-progress>
  </div>
</section>

<section id="questions">
  <form id="medicare-form" name="medicare-form" action="javascript: void()">
  <h1 *ngIf="activeQuestion.lastQuestion && activeQuestion.lastQuestion === true">🔐 Your results are ready.</h1>
  <h1>{{replaceVehicleInfoOnText(activeQuestion.label)}}</h1>
  <div *ngFor="let ans of activeQuestion.answers; let i = index">
    <div *ngIf="ans.isInput === false;" class="button-container">
      <button class="btn btn-primary btn-lg btn-car-insur"  (click)="getAnswer(activeQuestion.name, ans.value)">{{ans.label}}</button>
    </div>
    <div #isinputquestion *ngIf="ans.isInput === true && (!activeQuestion.is_dropdown && !activeQuestion.is_name);">

      <!-- Default question for non address, phone or zip-->
      <input *ngIf="activeQuestion.name !== 'address' && activeQuestion.name !== 'phone' && activeQuestion.name !== 'zip'"
        class="form-control single-input" 
        [pattern]="activeQuestion.pattern !== '' ? activeQuestion.pattern : ''" 
        type="text" placeholder="{{ans.label}}"
        [(ngModel)]="ans.value" 
        [name]="activeQuestion.name" />

        <input *ngIf="activeQuestion.name === 'zip'"
        class="form-control single-input" 
        type="tel"
        [mask]="'99999'"
        placeholder="00000"
        [name]="activeQuestion.name"
        [(ngModel)]="ans.value" 
        (keyup)="checkzipcode(ans.value)" />

        <input *ngIf="activeQuestion.name === 'phone'"
        class="form-control single-input" 
        [pattern]="activeQuestion.pattern !== '' ? activeQuestion.pattern : ''" 
        type="tel"
        [mask]="'(000) 000-0000'"
        placeholder="(000) 000-0000"
        [name]="activeQuestion.name"
        [(ngModel)]="ans.value" />

        <input *ngIf="activeQuestion.name === 'address'"
        class="form-control single-input" 
        ngx-google-places-autocomplete
        (onAddressChange)="handleAddressChange($event)"
        [options]="{
          types: [],
          componentRestrictions: { country: 'US' }
          }"
        #placesRef="ngx-places"
        [pattern]="activeQuestion.pattern !== '' ? activeQuestion.pattern : ''" 
        type="text" placeholder="{{ans.label}}"
        [name]="activeQuestion.name"
         />


    </div>
  </div>

  <div *ngIf="(activeQuestion.is_name === true);">
    <div class="container">
      <div class="row name-container">
        <ng-container *ngFor="let ans of activeQuestion.answers">
          <div class="col-md-12">
            <input class="form-control name-fields" type="text" placeholder="{{ans.label}}" *ngIf="ans.label==='Name'" [(ngModel)]="pingpostparams.firstName" [name]="'firstName'" />
            <input class="form-control name-fields" type="text" placeholder="{{ans.label}}" *ngIf="ans.label==='Last Name'" [(ngModel)]="pingpostparams.lastName" [name]="'lastName'"/>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div #isdropdownquestion *ngIf="(activeQuestion.is_dropdown === true);">

    <div class="container">

      <div class="row dob-container" *ngIf="activeQuestion.is_dob">

        <ng-container *ngFor="let ans of activeQuestion.answers">          
          <div class="col-md-5 col-sm-5 col-12 dob-col" *ngIf="ans.label==='Month'">
            <select class="form-select" [name]="'dobMonth'" id="" *ngIf="ans.label==='Month'" [(ngModel)]="pingpostparams.dob_month">
              <option value="" selected disabled>Month</option>
              <option value="{{month.num}}" *ngFor="let month of months">{{month.name}}</option>
            </select>
          </div>

          <div class="col-md-4 col-sm-4 col-12 dob-col" *ngIf="ans.label==='Day'">
            <select class="form-select" name="dobDay" id="" *ngIf="ans.label==='Day'" [(ngModel)]="pingpostparams.dob_day">
              <option value="" selected disabled>Day</option>
              <option value="{{day}}" *ngFor="let day of birthdayDates">{{day}}</option>
            </select>
          </div>          
          
          <div class="col-md-3 col-sm-3 col-12 dob-col" *ngIf="ans.label==='Year'">
            <select class="form-select" name="dobYear" id="" *ngIf="ans.label==='Year'" [(ngModel)]="pingpostparams.dob_year">
              <option value="" disabled>Year</option>
              <option value="{{year}}" *ngFor="let year of birthdayYears">{{year}}</option>
            </select>
          </div>

        </ng-container>        
      </div>   

      <div class="row" *ngIf="activeQuestion.is_year_list">
        <div class="col-md-12 col-12 col-sm-12" *ngIf="loadingYear">
          <img src="assets/images/infinity.svg" alt="SVG" class="cars-loading">
        </div>
        <ng-container *ngFor="let ans of activeQuestion.answers">
          <div class="col-md-4 col-sm-6 col-12 dropdown-buttons" *ngIf="parseInt(ans.value) > 1998 && ans.value.trim() !== ''">
            <button class="btn btn-primary btn-lg"  (click)="getAnswer(activeQuestion.name, ans.value)">{{ans.label}}</button>
          </div>
        </ng-container>        
      </div>      

      <div class="row" *ngIf="activeQuestion.is_make_list">
        <div class="col-md-12 col-12 col-sm-12" *ngIf="loadingMake">
          <img src="assets/images/infinity.svg" alt="SVG" class="cars-loading">
        </div>
        <ng-container *ngFor="let ans of activeQuestion.answers">
          <div class="col-md-4 col-sm-6 col-12 dropdown-buttons" *ngIf="this.buttonMakeList.includes(ans.value.toUpperCase())">
            <button class="btn btn-primary btn-lg"  (click)="getAnswer(activeQuestion.name, ans.value)">{{ans.label}}</button>
          </div>
        </ng-container>        
      </div> 

      <div class="row" *ngIf="activeQuestion.is_model_list">
        <div class="col-md-12 col-12 col-sm-12" *ngIf="loadingModel">
          <img src="assets/images/infinity.svg" alt="SVG" class="cars-loading">
        </div>
        <ng-container *ngFor="let ans of activeQuestion.answers">
          <div class="col-md-4 col-sm-6 col-12 dropdown-buttons" *ngIf="ans.value.trim() !== ''">
            <button class="btn btn-primary btn-lg"  (click)="getAnswer(activeQuestion.name, ans.value)">{{ans.label}}</button>
          </div>
        </ng-container>        
      </div> 

      <div class="row" *ngIf="activeQuestion.is_trim_list">
        <div class="col-md-12 col-12 col-sm-12" *ngIf="loadingTrim">
          <img src="assets/images/infinity.svg" alt="SVG" class="cars-loading">
        </div>
        <ng-container *ngFor="let ans of activeQuestion.answers">
          <div class="col-md-4 col-sm-6 col-12 dropdown-buttons" *ngIf="ans.value.trim() !== ''">
            <button class="btn btn-primary btn-lg"  (click)="getAnswer(activeQuestion.name, ans.value)">{{ans.label}}</button>
          </div>
        </ng-container>        
      </div> 


    </div>

    <div *ngIf="activeQuestion.is_year_list">
      <p class="browse-oters">Select other year</p>
      <select class="form-select year-drop" (change)="getAnswer(activeQuestion.name, $event.target.value)" name="carOtherYear">
        <option value="" disabled selected>Prior years</option>
        <ng-container *ngFor="let ans of activeQuestion.answers">
          <option value="{{ans.value}}" *ngIf="!isNumber(parseInt(ans.value)) || parseInt(ans.value) <= 1998" [disabled]="ans.disabled">{{ans.label}}</option>
        </ng-container>      
      </select>
    </div>

    <div *ngIf="activeQuestion.is_make_list">
      <p class="browse-oters">Select other make</p>
      <select class="form-select year-drop" (change)="getAnswer(activeQuestion.name, $event.target.value)" name="carOtherMake">
        <ng-container *ngFor="let ans of activeQuestion.answers">
          <option value="{{ans.value}}" *ngIf="!this.buttonMakeList.includes(ans.value.toUpperCase())" [disabled]="ans.disabled">{{ans.label}}</option>
        </ng-container>      
      </select>
    </div>


  </div>

  <p class="error-message" *ngIf="currentQError !== ''">{{currentQError}}</p>
  <button *ngIf="showActiveQuestionButton" class="btn btn-primary btn-lg btn-car-insur" (click)="getAnswer(activeQuestion.name, activeQuestion.answers)">{{activeQuestion.button_text || 'Next'}}</button>
  <br/><br/>
  <div id="tcpa-text" *ngIf="activeQuestion.lastQuestion && activeQuestion.lastQuestion === true">
    <br />
    <p>
      <input type="hidden" id="leadid_tcpa_disclosure" /><label for="leadid_tcpa_disclosure">By clicking “View My Results” immediately above, I provide my express written consent and electronic signature to receive marketing emails, calls and/or text messages (SMS) from Car Insurance Deals or its <a [routerLink]="['/partners']" target="_blank">network partners</a> at the email address or telephone number provided, even if my telephone number is currently on any federal, state or local do-not-call registry, including using an automatic telephone dialing system and artificial voice or pre-recorded messages. I also confirm that I have read and agree to this website’s <a [routerLink]="['/terms-and-conditions']" target="_blank">Terms of Use</a> and <a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a>. and I understand that I am not required to provide my consent as condition of purchasing any property, goods or services. I can revoke my consent through any reasonable means, at any time. To receive quotes without providing consent, please call <a href="tel:+18886041151" target="_blank" >(888) 604-1151</a>. Carrier data rates may apply.</label>
    </p>
    <br />
  </div>
  <img class="trusted-img" src="../../assets/images/trusted.png" alt="Trusted site">
  <br/><br/>
  <p class="or-call">OR CALL <a href="tel:+18886041151">(888) 604-1151 TTY:711</a></p>
  <input id="leadid_token" name="universal_leadid" type="hidden" value=""/>
</form>
</section>

<div id="LeadiDscript" type="text/javascript"> </div>

<app-site-footer></app-site-footer>