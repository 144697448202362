<section id="footer">
    <div class="footer-background">
        <div id="container">
            <br />
            <div id="footer-content">
                <p class="footer-content-1">*Partner Companies are entities that can provide quotes or connect consumers with the companies that can provide quotes.</p>
                <p class="footer-content-2">We are not an insurance carrier, agent, or broker and are not affiliated with any plan or endorsed by any government entity or agency. We connect individuals with insurance providers and other affiliates (collectively, “<a [routerLink]="['/partners']" target="_blank">partners</a>”) to give you, the consumer, an opportunity to get information about insurance and connect with licensed insurance agents. By completing the quotes form or calling the number listed above, you will be directed to a partner that can connect you to an appropriate licensed insurance agent who can answer your questions and discuss plan options.</p>
                <p class="footer-content-2">Products and services are provided exclusively by our partners, but not all offer the same plans or options. Descriptions are for informational purposes only and subject to change. We encourage you to shop around and explore all your options. We are not affiliated with or endorsed by any government entity or agency.</p>
                <p class="footer-content-2">By using this site, you acknowledge that you have read and agree to our <a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a> and <a [routerLink]="['/terms-and-conditions']" target="_blank">Terms & Conditions</a>.</p>
            </div>
        </div>
    </div>
    <div id="bottom-links">
        <div class="row">
            <div class="col-md-6">
                <p>Copyright © {{currentYear}}. Car-insurance-deals.com All Rights Reserved.</p>
            </div>
            <div class="col-md-6">
              <p class="desktop"><a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a> | <a [routerLink]="['/terms-and-conditions']" target="_blank">Terms & Conditions</a> | <a [routerLink]="['/do-not-sell']" target="_blank">Do Not Sell My Personal Information</a></p>
              <p class="mobile"><a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a></p>
              <p class="mobile"><a [routerLink]="['/terms-and-conditions']" target="_blank">Terms & Conditions</a></p>
              <p class="mobile"><a [routerLink]="['/do-not-sell']" target="_blank">Do Not Sell My Personal Information</a></p>
            </div>
        </div>
    </div>
</section>
