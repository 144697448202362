import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CampaignFlowComponentComponent } from './campaign-flow-component/campaign-flow-component.component';
import { SiteHeaderComponent } from './shared/site-header/site-header.component';
import { SiteFooterComponent } from './shared/site-footer/site-footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatGridListModule} from '@angular/material/grid-list';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FormsModule } from '@angular/forms';
import { ThankyouPageComponent } from './thankyou-page/thankyou-page.component';
import { HttpClientModule } from '@angular/common/http';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermnsConditionsComponent } from './termns-conditions/termns-conditions.component';
import { DonotSellComponent } from './donot-sell/donot-sell.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { PartnersListComponent } from './partners-list/partners-list.component';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { PixelModule } from 'ngx-pixel';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    CampaignFlowComponentComponent,
    SiteHeaderComponent,
    SiteFooterComponent,
    ThankyouPageComponent,
    PrivacyPolicyComponent,
    TermnsConditionsComponent,
    DonotSellComponent,
    PartnersListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatGridListModule,
    FormsModule ,
    HttpClientModule,
    GooglePlaceModule,
    NgxMaskModule.forRoot(maskConfig),
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),
    PixelModule.forRoot({ enabled: true, pixelId: '2328174433878395' })
  ],
  providers: [
    {provide: 'googleTagManagerId',  useValue: 'GTM-PZPVVGD'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
