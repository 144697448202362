<app-site-header></app-site-header>

<section id="text-page">

    <h1>Terms and conditions</h1>
  
    <br /><br />
  
    <p>The following terms and conditions (the "Terms of Use") govern your use of the services offered through car-insurance-deals.com (collectively, the "Site"). By using the Site, you accept and agree to these Terms of Use. If you do not agree to these Terms of Use, please do not use or access the Site. The terms “we,” “us,” or “the Company” all refer to car-insurance-deals.com.</p>
  
    <p><strong>Accessing our Site</strong></p>
  
    <p>You may only use the Site if you live in the United States and are at least 18 years old. Otherwise, you may not use our Site. We reserve the right, for any reason, in our sole discretion, to terminate, change, suspend, and/or discontinue any aspect of the Site. We may also impose limits on certain features of the Site or restrict your access to part of or the entire Site, without notice or penalty. car-insurance-deals.com does not charge users for use of the Site.</p>
  
    <p><strong>What We Do</strong></p>
  
    <p>The Site enables insurance agents, insurance companies, carriers, and other similar parties who are customers of the Site (each a "Service Provider") to offer for sale and to sell, insurance and other products to you. Once you provide us with the information required on our form, we attempt to match you with appropriate insurance agents, brokers, carriers or other service providers to help you acquire the type of insurance you are seeking. We do not issue insurance contracts or bind coverage. We are not responsible for, nor do we assume any financial or other liability whatsoever, for the conduct of any Service Provider. We are not an insurance agent or insurance carrier, and we do not issue insurance contracts or bind coverage ourselves, nor do we endorse or recommend any companies or specific types of insurance policies. While we publish content on the Site about insurance, we do not provide insurance, tax or financial advice of any kind. We do not guarantee that any Service Providers to whom we forward your application will contact you or agree to provide you coverage. We are not responsible in any way for the conduct of our Service Providers that are matched with your insurance application. The coverage provided by any Service Provider will be subject the terms and conditions of the insurance policy under which it issued, and we have no control over such terms and conditions.</p>
  
    <p><strong>User Data; Consent to be Contacted</strong></p>
  
    <p>In the course of your use of the Site, you may be asked to provide information or materials to us ("User Data"). User Data includes, for example, information you submit to us via your application to receive quotations. Our information collection and usage practices are set forth in our Privacy Policy which is incorporated herein by reference. You acknowledge and agree that you are solely responsible for the accuracy and content of the User Data. We may delete or destroy any such User Data at any time. We reserve the right to refuse to post or to remove any User Data, in whole or in part, in our sole discretion, for any reason.</p>
  
    <p>By providing your contact information to us through the Site, you agree to receive communications from us directly, on our website or through a third party. We will periodically send you e-mails with offers and promotions. We may also contact you by telephone using an automated dialing system along with a pre-recorded message or interactive voice response system. If you no longer wish to receive these communications, please let us know by sending an email to car-insurance-deals.com Customer Care at info@car-insurance-deals.com. You may also opt-out by clicking on the unsubscribe link in any of our emails.</p>
  
    <p>In addition, by submitting a request for quotes through the Site, you consent to be contacted by our Service Providers. Once your information is submitted to these Service Providers, any request to opt-out or unsubscribe from their communications must be directed to the Service Providers.</p>
  
    <p><strong>Exclusive Company Ownership of Rights</strong></p>
  
    <p>The Site and any necessary software used in connection with the Site contain proprietary and confidential information that is protected by intellectual property laws in applicable jurisdictions. You acknowledge and agree that information and materials presented through the Site is protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws. Except as expressly permitted by applicable law or as authorized by us, you agree not to modify, sell, distribute, transmit, broadcast, publicly perform or create derivative works based on the Site, in whole or in part. Any commercial use of the Site, or any portion thereof, by you is strictly prohibited.</p>
  
    <p>car-insurance-deals.com grants you a personal, non-transferable and non-exclusive right and license to use the Site on a computer or other Internet device; provided that you do not (and do not allow any third party to) copy, reproduce, distribute, reverse engineer or otherwise exploit any content, code, data or materials on the Site. You agree not to modify the Site in any manner or form, nor to use modified versions of the Site for any purpose. We do not grant any license or other authorization to any user to use our trade names, trademarks, service marks or other marks or logos or those of our partners without our separate express written agreement. Third party marks are the property of their respective owners.</p>
  
    <p>Any questions, comments, suggestions, or materials submitted to us through the Site will become our sole property. We will own all rights in such materials, and have the unrestricted right to use, publish and otherwise disseminate such information for any purpose, without attribution or compensation.</p>
  
    <p><strong>DISCLAIMER OF WARRANTIES</strong></p>
  
    <p>THE SITE, INCLUDING ALL CONTENT, SERVICES, FUNCTIONS, SOFTWARE, MATERIALS AND INFORMATION MADE AVAILABLE ON OR ACCESSED THROUGH THE SITE, IS PROVIDED "AS IS." TO THE FULLEST EXTENT PERMISSIBLE BY LAW, WE MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND WHATSOEVER FOR THE CONTENT ON THE SITE OR THE SERVICES, INFORMATION, FUNCTIONS AND MATERIALS, MADE ACCESSIBLE BY THE SOFTWARE USED ON OR ACCESSED THROUGH THE SITE, FOR ANY SERVICES OR PRODUCTS OR HYPERTEXT LINKS TO THIRD PARTIES OR FOR ANY BREACH OF SECURITY ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE INFORMATION THROUGH THE SITE OR ANY LINKED SITE. FURTHER, WE EXPRESSLY DISCLAIM ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, WITHOUT LIMITATION, NON-INFRINGEMENT, TITLE, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE. WE DO NOT WARRANT THAT THE FUNCTIONS CONTAINED IN THE SITE OR ANY SERVICES, CONTENT OR MATERIALS CONTAINED THEREIN WILL MEET YOUR NEEDS, BE UNINTERRUPTED OR ERROR FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE OR THE SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</p>
  
    <p><strong>LIMITATION OF LIABILITY</strong></p>
  
    <p>IN NO EVENT WILL THE COMPANY, THE SITE, OUR SUBSIDIARIES, AFFILIATES, OFFICERS, DIRECTORS, SHAREHOLDERS, AND/OR EMPLOYEES BE LIABLE FOR ANY LOST PROFITS, COVER, LOSS OF REVENUE OR ANY INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES ARISING OUT OF, BASED ON, OR RESULTING FROM YOUR USE OF THE SITE AND/OR ANY TRANSACTION BETWEEN PROVIDERS AND PROSPECTS OR BETWEEN SITE USERS GENERALLY, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THESE LIMITATIONS AND EXCLUSIONS APPLY WITHOUT REGARD TO WHETHER THE DAMAGES ARISE FROM (i) BREACH OF CONTRACT, (ii) BREACH OF WARRANTY, (iii) STRICT LIABILITY, (iv) TORT, (v) NEGLIGENCE, OR (vi) ANY OTHER CAUSE OF ACTION, TO THE MAXIMUM EXTENT SUCH EXCLUSION AND LIMITATIONS ARE NOT PROHIBITED BY APPLICABLE LAW. IF YOU ARE DISSATISFIED WITH THE SITE, IF YOU DO NOT AGREE WITH ANY PART OF THIS AGREEMENT, OR IF YOU HAVE ANY OTHER DISPUTE OR CLAIM WITH OR AGAINST THE COMPANY, ANOTHER USER OR THE SITE WITH RESPECT TO THESE TERMS OR THE SITE ITSELF, THEN YOUR SOLE AND EXCLUSIVE REMEDY AGAINST US IS TO DISCONTINUE USING THE SITE. IN ALL EVENTS, OUR LIABILITY AND THE LIABILITY OF ANY MEMBER OF THE COMPANY, TO YOU OR TO ANY THIRD PARTY IN ANY CIRCUMSTANCE ARISING OUT OF OR IN CONNECTION WITH THE SITE IS LIMITED TO THE GREATER OF (i) AMOUNT OF FEES ACTUALLY RECEIVED BY US FROM YOU DURING THE CALENDAR MONTH DURING WHICH SUCH ALLEGED CLAIM(S) ACCRUED, AND (ii) $200.00.</p>
  
    <p><strong>Links</strong></p>
  
    <p>car-insurance-deals.com provides links to other sites for informational purposes only. We do not control and, thus, have no responsibility for the accuracy of information provided by these other sites. The availability of these links does not constitute an endorsement of or association with such sites or the content, products, advertising or other materials presented on such sites. You acknowledge and agree that we are not responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with your use of or reliance on any content, goods or services available on such sites.</p>
  
    <p><strong>Updates</strong></p>
  
    <p>These Terms of Use are subject to change from time to time and at any time, and such changes will be effective upon posting to the Site. Use of the Site following any modifications to the Terms of Use signifies your acceptance of such modifications.</p>
  
    <p><strong>Applicable Laws</strong></p>
  
    <p>This agreement is governed by the laws of the State of Delaware, United States of America, excluding its choice of law rules. You irrevocably agree that such jurisdiction and venue will be the sole and exclusive jurisdiction and venue of any legal dispute. You covenant not to sue us in any other forum for any cause of action. Our failure to exercise or enforce any right or provision of these Terms shall not constitute a waiver of any such right or provision. If for any reason a court of competent jurisdiction finds any provision of these Terms, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to affect the intent of these Terms, and the remainder of these Terms shall continue in full force and effect.</p>
  
    <p><strong>ARBITRATION AGREEMENT</strong></p>
  
    <p>Any dispute or claim relating in any way to your use of this website, including any related calls texts or other communications, will be resolved by binding arbitration, rather than in court, except that you may assert claims in small claims court if your claims qualify. This includes claims against our clients, vendors, and Marketing Partners, which are third party beneficiaries of this arbitration agreement. The Federal Arbitration Act and federal arbitration law apply to this agreement. There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must follow the terms of these terms as a court would. The arbitration may be conducted by the American Arbitration Association (AAA) under its rules, including the AAA's Supplementary Procedures for Consumer-Related Disputes. The AAA's rules are available at www.adr.org or by calling 1-800-778-7879. You may choose to have the arbitration conducted by telephone, based on written submissions, or in person in the county where you live or at another mutually agreed location. We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. If for any reason a claim proceeds in court rather than in arbitration we each waive any right to a jury trial. We also both agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights."</p>
  
  </section>

  <app-site-footer></app-site-footer>