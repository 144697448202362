

<section id="policybind-form">

    <div id="form-content">
  
      <img routerLink="/" src="../assets/images/car-logo.png" alt="Medicare Plan Help" id="top-logo-img">
  
      <!--Results Step-->
      <div class="step" id="step7">
  
  
        <div class="reviewing-details">
  
          <div class="top-part"  *ngIf="details">
            <h2  *ngIf="miniSteps.congrats">Congratulations!</h2>
            <h3>
              <span *ngIf="miniSteps.details">Reviewing details...</span>
              <span *ngIf="miniSteps.location">Searching location...</span>
              <span *ngIf="miniSteps.options">Matching you with auto insurance plans...</span>
              <span *ngIf="miniSteps.availability">Checking availability...</span>
              <span *ngIf="miniSteps.congrats">We've found plans in your area</span>
            </h3>
            <div class="container">
              <div class="row">
                <div class="col-md-12 images">
                  <img src="./assets/images/details.png" alt="Details" [class]="miniSteps.details ? 'active' : 'inactive'">
                  <img src="./assets/images/location.png" alt="Location" [class]="miniSteps.location ? 'active' : 'inactive'">
                  <img src="./assets/images/options.png" alt="Options" [class]="miniSteps.options ? 'active' : 'inactive'">
                  <img src="./assets/images/calendario.png" alt="Availability" [class]="miniSteps.availability ? 'active' : 'inactive'">
                  <img src="./assets/images/congrats.png" alt="Congrats" [class]="miniSteps.congrats ? 'active' : 'inactive'">
                </div>
              </div>
            </div>
          </div>
  
          <div class="step-content" *ngIf="clicks">
            <div class="ptagcontainer">
              <img src="./assets/images/ptag.png" alt="Discounts" id="ptag">
              <div class="thankyou-header">
  
                <p class="congrats">
                  Click your free quotes below!
                </p>  
                <p class="user-summary"> We highly recommend clicking 3-4 quotes below to compare the best prices!</p>  
              </div>
                <div>
                  <div id="mediaalpha_placeholder"></div>
                </div> 
   
            </div>
          </div>

          <div class="step-content" *ngIf="agentsAvailable">
            <div class="ptagcontainer">
              <img src="./assets/images/ptag.png" alt="Discounts" id="ptag">
              <div class="thankyou-header">  
                <p class="congrats">
                  Licensed Agents Available Now!
                </p>  
              </div>

              <div class="agent-body">
                <p class="speak"> Speak to a licensed insurance agent </p>
                <a href="tel:+18886041151"ng serve class="btn btn-block">(888) 604-1151</a>
                <p class="available">Your licensed agent is reserved for the next <strong>{{agentTimer}}</strong></p>
              </div>
   
            </div>
          </div>
  
          <div class="col-md-12 devide">
            <hr />
          </div>
  
          <div class="container bottom">
            <div class="row">
              <div class="col-md-2 promise-check">
                <img src="./assets/images/gold-check.jpg" alt="Gold Check Mark" id="gold-check-mark">
              </div>
              <div class="col-md-10 promise-desc">
                  <p class="promise-title">
                    The Car Insurance Deals Promise
                  </p>
  
                  <p class="promise-content">At Car Insurance Deals, we want to make sure you get the best quotes possible... and we do this based on car insurance plans in your area, for FREE. Our state-of-the-art technology is backed by a quality screening process, and will only recommend the best
                    car insurance plans available for you.
                  </p>
              </div>
            </div>
          </div>
  
        </div>
  
  
      </div>
  
    </div>
  
  </section>

<app-site-footer></app-site-footer>
