import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutoQuestions } from '../models/auto-questions.model';
import { PingPostModel } from '../models/pingpost.model';
import { CarsApiService } from '../shared/car-marke-model-db/cars-api.service';
import { HttpClient } from '@angular/common/http';
import { DoPost } from '../shared/do-post';
import * as _ from 'lodash';
import { DatePipe, LocationStrategy } from '@angular/common';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { PixelService } from 'ngx-pixel';
import { IpServiceService } from '../ip-service.service';
import * as moment from 'moment';


@Component({
  selector: 'app-campaign-flow-component',
  templateUrl: './campaign-flow-component.component.html',
  styleUrls: ['./campaign-flow-component.component.scss']
})
export class CampaignFlowComponentComponent implements OnInit {


  //Progress step variables
  public stepText = 'START!';
  public stepTextSize = '30';
  public showTitleAndUnit = false;
  public progressNumber: number = 0;
  public currentQuestionNumber = 0;
  //Quetions flow variables
  public questions;
  public pingpostparams;
  public activeQuestion;
  public previousQuestions = [];
  //Car API Response variables
  public years: string [];
  public makes;
  public models;
  public trims;
  //Loading svg flags
  public loadingYear: boolean = true;
  public loadingMake: boolean = true;
  public loadingModel: boolean = true;
  public loadingTrim: boolean = true;

  public currentVehicles: number = 1;
  public maxVehiclesAllowed: number = 2;

  public currentQError: string = '';

  public bannerText: string = 'See How Much We Can Save You!';
  public doPost: any;

  constructor(
      private router: Router,
          private carsService: CarsApiService,
            private http:HttpClient,
              private gtmService: GoogleTagManagerService,
              private locationStrategy: LocationStrategy,
                private pixel: PixelService,
                  private ip:IpServiceService) {

    this.questions = new AutoQuestions;
    this.pingpostparams = new PingPostModel().params;
    this.doPost = new DoPost(http);

    this.birthdayDates = Array(31).fill(0).map((x, i) => i+1);
    this.fillYearsArray();
  }

  ngOnInit(): void {
    this.pingpostparams.LeadIdentifier = Date.now().toString(36) + Math.random().toString(36).substr(2);
    this.trackFacebookConversion();
    this.getIpInfo();
    this.preventBackButton();
    this.loadTCPAScripts();
    this.getUserAgent();
    this.getYears();
    this.first();
    this.updateProgressCircle();
  }

  getIpInfo()
  {
    this.ip.getIPAddress().subscribe((res:any)=>{
      this.pingpostparams.ip=res.ip;
    });
  }

  preventBackButton() {
    history.pushState(null, location.href);
    const $this = this;
    this.locationStrategy.onPopState(() => {
        history.pushState(null, location.href);
        this.previousQuestion();
    });
  }

  loadTCPAScripts(){
    const $this = this;
    setTimeout(function(){
      $this.leadId();
      $this.trustedForm();
      $this.gtmService.addGtmToDom();
      $this.loadScripts();
    }, 800);
  }

  checkzipcode(zipcode: string){
    //TODO: validate if viewport is mobile so transition happens automatically
    if (zipcode && zipcode.length >= 5){
      this.getAnswer(this.activeQuestion.name, this.activeQuestion.answers);
    }
  }

  loadScripts() {

    const dynamicScripts = [
     'https://script.crazyegg.com/pages/scripts/0108/2990.js'
    ];

    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      document.getElementsByTagName('head')[0].appendChild(node);
    }

  }

  getTrustedAndLeadId(){
    
    const leadIdCookieName: string = "leadid_token-9EBE819B-C829-56B0-9B9E-5C0213B85E55-EC347BC8-0D97-8A24-7DB3-0695CCA6FDBB";

    let leadId = this.getCookie(leadIdCookieName) || (<HTMLInputElement>document.getElementById('leadid_token')).value || '';
    let certTokenParts = (<HTMLInputElement>document.getElementById('xxTrustedFormCertUrl_0')).value.split('/') || [];
    let certToken = certTokenParts.length > 0 ? certTokenParts[certTokenParts.length - 1] : '';
    
    this.pingpostparams.jornayaLeadId = leadId;
    this.pingpostparams.trustedFormCertificate = certToken;  
    

    if(leadId === '' || certToken === '') {
      return false;
    }

    return true;

  }

  getCookie(cookieName: string) {
    return (document.cookie.match('(?:^|;)\\s*'+cookieName.trim()+'\\s*=\\s*([^;]*?)\\s*(?:;|$)')||[])[1] || null;
  }


  getUserAgent(){
    this.pingpostparams.userAgent = window.navigator.userAgent; 
  }

  isNumber(val: any): boolean { 
    return typeof val === 'number'; 
  }
  parseInt(val: string){
    return parseInt(val);
  }

  updateProgressCircle(){
    this.progressNumber = this.activeQuestion.progress;
    if(this.progressNumber > 0) {
      this.stepTextSize = '16';
      this.stepText = 'COMPLETED';
      this.showTitleAndUnit = true;
    }
  }

  getTotalPresentedQuestions(){
    //I need to make it so that the percentage is based on the questions 
    //displayed to the user and not the total questions in the Array.
    return this.questions.Questions.length;
  }

  first(){
    this.activeQuestion = this.questions.getFirstQuestion;
  }

  getAnswer(currentQuestion: string, currentAnswer: any){
    const answer = this.extractAnswerString(currentAnswer);
    this.validateMaxAllowedVehicles(currentQuestion, answer);

    //Handle last question wich is phone question
    if(currentQuestion === "phone"){
      this.lastQuestion(currentQuestion, answer);
      return false;
    }

    if(this.validateRequiredFields(currentQuestion, answer) === true){
      this.currentQError = '';
      this.handleYearMakeModelTrimTriggers(currentQuestion, currentAnswer);
      this.nextQuestion(currentQuestion, answer);
      this.assignAnswerToParam(currentQuestion, answer);
    } else {
      this.currentQError = this.validateRequiredFields(currentQuestion, answer);
    }
    
    //Do this often to make sure we get it
    this.getTrustedAndLeadId();
  }

  lastQuestion(currentQuestion: string, answer: string){
      //real time validation of phone number
      const denyPhone = ["disconnected", "disconnected-70", "unreachable", "invalid-phone", "ERROR", "invalid-format"];
      const url = "https://api.realvalidation.com/rpvWebService/RealPhoneValidationTurbo.php?output=json&token=D08E6C95-DDAF-4D48-B834-9F5F96809905&phone=";

      this.http.get<any>(url + answer).subscribe(response => {
        if(denyPhone.includes(response.status)){

          this.currentQError = 'Please enter a valid mobile or landline phone number.';          
        } else {
            //Change to thankyou page
            this.assignAnswerToParam(currentQuestion, answer);
            this.finalSteps();
            this.currentQError = '';
        }
      });
  }

  extractAnswerString(currentAnswer){
    let finalAnswer = [];
    //IF it is an array we add all elements and join by blank space
    if(Array.isArray(currentAnswer)){
      currentAnswer.forEach(element => {
        finalAnswer.push(element.value);
      });
    } else {
      //If not an array i "Has" to be a string
      finalAnswer.push(currentAnswer);
    }

    return finalAnswer.join(" ");
  }

  assignAnswerToParam(currentQuestion: string, currentAnswer: any = ""){
    if(currentQuestion !== 'dob'){
      this.pingpostparams[currentQuestion] = currentAnswer; 
    }
    console.log(this.pingpostparams);
  }

  nextQuestion(currentQuestion: string, currentAnswer: any){
    this.activeQuestion = this.questions.next(currentQuestion, 
        currentAnswer, 
          this.currentVehicles, 
            this.maxVehiclesAllowed);

    if(typeof this.activeQuestion === 'object'){
      this.currentQuestionNumber = this.currentQuestionNumber + 1;
      this.previousQuestions.push(currentQuestion);
      this.updateProgressCircle();
    }
  }

  previousQuestion(){
    let lastQuestionIndex = this.previousQuestions.length - 1;
    let prevQuestionName = "";

    if(lastQuestionIndex < 0){
      return false;
    }

    prevQuestionName = this.previousQuestions.pop();
    this.activeQuestion = this.questions.prev(prevQuestionName);
  }

  isNumeric(value: any) {
    return /^-?\d+$/.test(value);
  }

  validateRequiredFields(questionName: string, answer: string = "") {
    const cQ = this.questions.getQuestionByName(questionName);

    //DOB Validation
    if(cQ.is_dob){
      if(_.isEmpty(this.pingpostparams.dob_day) || _.isEmpty(this.pingpostparams.dob_month) || _.isEmpty(this.pingpostparams.dob_year)) {
        return cQ.error_message;
      }else{
        this.pingpostparams.dob = this.formatDate(this.pingpostparams.dob_year, this.pingpostparams.dob_month, this.pingpostparams.dob_day);
        console.log(this.pingpostparams.dob);
      }
    }

    //Zipcode validation
    if(cQ.name === "zip"){
      if(answer.length !== 5 || !this.isNumeric(answer)){
        return cQ.error_message;
      }else{
        this.zipcodeLookup(answer);
      }      
    }

    //Address validation
    if(cQ.name === "address"){
      if(_.isEmpty(answer)){
        return cQ.error_message;
      }     
    }

    //Address validation
    if(cQ.name === "email"){
      if(!this.validateEmail(answer)){
        return cQ.error_message;
      }     
    }

    //Name validation
    if(cQ.name === "name"){
      if(_.isEmpty(this.pingpostparams.firstName) || _.isEmpty(this.pingpostparams.lastName)){
        return cQ.error_message;
      }     
    }


    //Default returns true
    return true;
  }

  handleAddressChange(address: any){
    this.pingpostparams.address = address.formatted_address;
    this.activeQuestion.answers[0].value = this.pingpostparams.address;
  }

  public validateEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  //CAR API CALLS
  getYears(){
    this.loadingYear = true;
    this.carsService.getYears().subscribe(response => {
      
      if(response.length <= 0){
        return false;
      }

      response.forEach(carYear => {
        this.questions.Questions.find(question => {

          if((question.name === "vehicleYear" || question.name === "vehicleYear2") && 
            parseInt(carYear.year) > 1980){
            question.answers.push(
              {
                label: carYear.year, 
                value: carYear.year, 
                isInput: true, next: null
              }
            );
          }
        });

      });

      this.loadingYear = false;

    });
  }

  getMakesByYear(questionName: string, year: string){
    this.loadingMake = true;
    this.carsService.getMakesByYear(year).subscribe(response => {
        
      if(response.length <= 0){
        return false;
      }

      response.forEach(carMake => {
        this.questions.Questions.find(question => {

          if(question.name === questionName){
            question.answers.push(
              {
                label: carMake.make, 
                value: carMake.make, 
                isInput: true, next: null
              }
            );
          }
        });

      });

      this.loadingMake = false;

    });
  }

  getModelsByMake(questionName: string, year: string, make: string){
    this.loadingModel = true;
    this.carsService.getModelsByMake(year, make).subscribe(response => {
        
      if(response.length <= 0){
        return false;
      }

      response.forEach(carModel => {
        this.questions.Questions.find(question => {

          if(question.name === questionName){
            question.answers.push(
              {
                label: carModel.model, 
                value: carModel.model, 
                isInput: true, next: null
              }
            );
          }
        });

      });

      this.loadingModel = false;

    });
  }

  getTrimsByModel(questionName: string, year: string, make: string, model: string){
    this.loadingTrim = true;
    this.carsService.getTrimsByModel(year, make, model).subscribe(response => {
        
      if(response.length <= 0){
        return false;
      }

      response.forEach(carTrim => {
        this.questions.Questions.find(question => {

          if(question.name === questionName){
            question.answers.push(
              {
                label: carTrim.trim, 
                value: carTrim.trim, 
                isInput: true, next: null
              }
            );
          }
        });

      });

      this.loadingTrim = false;

    });
  }

  handleYearMakeModelTrimTriggers(paramName: string, triggerValue: any){
    switch (paramName){
      case 'vehicleYear':
          this.getMakesByYear('vehicleMake', triggerValue);
        break;
      case 'vehicleMake':
        this.getModelsByMake('vehicleModel', this.pingpostparams.vehicleYear, triggerValue);
        break;
      case 'vehicleModel':
        this.getTrimsByModel('vehicleTrim', this.pingpostparams.vehicleYear, this.pingpostparams.vehicleMake, triggerValue);
        break;

      case 'vehicleYear2':
        this.getMakesByYear('vehicleMake2', triggerValue);
        break;
      case 'vehicleMake2':
        this.getModelsByMake('vehicleModel2', this.pingpostparams.vehicleYear2, triggerValue);
        break;
      case 'vehicleModel2':
        this.getTrimsByModel('vehicleTrim2', this.pingpostparams.vehicleYear2, this.pingpostparams.vehicleMake2, triggerValue);
        break;


    }
  }

  validateMaxAllowedVehicles(currentQuestionName: string = "", answer: string = "") {
    if(currentQuestionName === "anotherVehicle" && answer === "yes"){
      this.currentVehicles++;
    }
  }

  showThankYouPage(){
    this.router.navigate(['auto/thankyou', {}]);
  }

  //Make sure the variables are present in the text you need to replace
  replaceVehicleInfoOnText(text: string = "") {
    let templateVariables = {
      "[MAKE]":this.pingpostparams.vehicleMake,
      "[MODEL]":this.pingpostparams.vehicleModel,
      "[MAKE2]":this.pingpostparams.vehicleMake2,
      "[MODEL2]":this.pingpostparams.vehicleModel2
    };

    
    for (const [key, value] of Object.entries(templateVariables)) {
      text =  text.replace(key, value);
    }
    
    return text;
  }

  fillYearsArray(){
    for(let y = 1920; y <= 2000; y++) {
      this.birthdayYears.push(y);
    }
  }

  zipcodeLookup(zipcode: string){
    this.http.get<any>('https://owned-and-operated-324915.uc.r.appspot.com/public/api/zipcode-lookup/' + zipcode).subscribe(response => {
      this.pingpostparams.city = response.data.city;
      this.pingpostparams.state = response.data.state;
    });
  }

  formatDate(y: string, m: string, d: string){
    if(y.trim() !== "" && m.trim() !== "" && d.trim() !== ""){
      const datepipe: DatePipe = new DatePipe('en-US');
      return datepipe.transform(m+'/'+d+'/'+y, 'YYYY-MM-dd') || "";
    } else {
      return "";
    }
  }

  trustedForm() {
    let field = 'xxTrustedFormCertUrl';
    let provideReferrer: any;
    let tf = document.createElement('script');
    tf.type = 'text/javascript'; tf.async = true;
    tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +
    '://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l='+new Date().getTime()+Math.random();
    let s = document.getElementsByTagName('script')[0]; s.parentNode && s.parentNode.insertBefore(tf, s);
   }

  leadId() {
    let s = document.createElement('script');
    s.id = 'LeadiDscript_campaign';
    s.type = 'text/javascript';
    s.async = true;
    s.src = '//create.lidstatic.com/campaign/ec347bc8-0d97-8a24-7db3-0695cca6fdbb.js?snippet_version=2';
    let LeadiDscript = document.getElementById('LeadiDscript');
    LeadiDscript && LeadiDscript.parentNode && LeadiDscript.parentNode.insertBefore(s, LeadiDscript);
  }

  trackFacebookConversion(){

    const $this = this;
    setTimeout(function(){
      $this.pixel.trackCustom('InitiateCheckout');
    }, 1100);   

    
  }

  finalSteps(){
    //this.getTrustedAndLeadId();
    //this.fullPostData.makeHttpPostRequest(this.formFields);
    //this.formFields.date = new Date().toString();
    this.saveLocalStorage();
    this.getTrustedAndLeadId();
    this.pingpostparams.date = moment().format('Y-mm-D h:m:s').toString();
    const $this = this;
    setTimeout(function(){
      $this.doPost.makeHttpPostRequest($this.pingpostparams);
      $this.pixel.trackCustom('Lead');
      $this.showThankYouPage();
    }, 500);
  }

  saveLocalStorage(){
    localStorage.setItem('zipcode', this.pingpostparams.zip);
  }

  // Getters
  get showActiveQuestionButton() {
      return this.activeQuestion.show_button === true;
  }

  //Default list values
  public buttonMakeList = [
    "AUDI","BMW","BUICK", "CADILLAC","CHEVROLET","CHRYSLER","DODGE","FORD","GMC","HONDA","HYUNDAI","JEEP","KIA","MERCEDES-BENZ","NISSAN","TOYOTA"
  ]

  //Days for birthday
  public birthdayDates: any;

  //Years for birthday
  public birthdayYears: any = [];

  //Moths for birthady
  public months = [
    {
      num: 1,
      name: 'January'
    },
    {
      num: 2,
      name: 'February'
    },
    {
      num: 3,
      name: 'March'
    },
    {
      num: 4,
      name: 'April'
    },
    {
      num: 5,
      name: 'May'
    },
    {
      num: 6,
      name: 'June'
    },
    {
      num: 7,
      name: 'July'
    },
    {
      num: 8,
      name: 'August'
    },
    {
      num: 9,
      name: 'September'
    },
    {
      num: 10,
      name: 'October'
    },
    {
      num: 11,
      name: 'November'
    },
    {
      num: 12,
      name: 'December'
    },
  ];

}
