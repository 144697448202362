import * as _ from 'lodash';

export class PingPostModel{
    public params = {
        "currentlyInsured":"",
        "currentInsurer":"",
        "insuredTime":"",
        "drivers":"",
        "vehicleYear":"",
        "vehicleMake":"",
        "vehicleModel":"",
        "vehicleTrim":"",
        "vehicleYear2":"",
        "vehicleMake2":"",
        "vehicleModel2":"",
        "vehicleTrim2":"",
        "anotherVehicle":"",
        "accidentsTickets":"",
        "dui":"",
        "sr22":"",
        "credit":"",
        "married":"",
        "homeOwner":"",
        "homeInsurance":"",
        "dob_month":"",
        "dob_day":"",
        "dob_year":"1980",
        "gender":"",
        "veteran":"",
        "zip":"",
        "city":"",
        "state":"",
        "address":"",
        "firstName":"",
        "lastName":"",
        "email":"",
        "phone":"",
        "userAgent":"",
        "jornayaLeadId":"",
        "trustedFormCertificate":"",
        "LeadIdentifier":"",
        "date":"",
        "ip":""
    }

}